import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class SuccessCreatedAndLinkedGoalViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.add-goals.success-created-and-linked-goal';

  go_investment_product_button_text = '';

  go_to_investment_dashboard_url = '';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  goToDashboardGoals = () => {
    window.location.href = '/goals-dashboard';
  }

  goToInvestmentDashboard = () => {
    window.location.href = this.go_to_investment_dashboard_url;
  }

  setGoInvestmentProductButtonText = (product_name: string) => {
    if (product_name.toLowerCase().includes('pocket')) {
      this.go_investment_product_button_text = this.translate('go_pocket');
      this.go_to_investment_dashboard_url = '/sowos-pocket';
    }

    if (product_name.toLowerCase().includes('wealth')) {
      this.go_investment_product_button_text = this.translate('go_wealth');
      this.go_to_investment_dashboard_url = '/sowos-wealth';
    }
  }

  initialize = async (product_name: string) => {
    this.setGoInvestmentProductButtonText(product_name);
  }
}
