





















































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';

import SuccessCreatedAndLinkedGoalViewModel from
  '@/vue-app/view-models/components/goals-dashboard/add-goals/link/sucess-created-and-linked-goal-view-model';

@Component({ name: 'SuccessCreatedAndLinkedGoalModal' })
export default class SuccessCreatedAndLinkedGoalModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('productName', { type: String, required: true })
  product_name!: string;

  @Watch('product_name')
  handleServiceChange(product_name: string) {
    this.success_created_and_linked_goal_model.initialize(this.product_name);
  }

  success_created_and_linked_goal_model = Vue.observable(
    new SuccessCreatedAndLinkedGoalViewModel(),
  );
}
